import * as React from 'react';
// import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import Container from './container';
import ArrowRightIcon from '../images/advantages/right-arrow-2.svg';
import StrategyIcon from '../images/advantages/strategy.svg';
import DesignIcon from '../images/advantages/design.svg';
import CodeIcon from '../images/advantages/code.svg';
import MaintenanceIcon from '../images/advantages/maintenance.svg';
import Header from './header';
import Link from './link';

function Item({ title, icon: Icon, children, className }) {
  return (
    <li
      className={classNames(
        'flex items-start space-x-5 md:block md:space-x-0 xl:flex xl:items-start xl:space-x-6',
        className
      )}
    >
      <div className="flex items-center justify-center w-12 h-12 text-indigo-500 bg-indigo-100 rounded-lg shrink-0 md:w-14 md:h-14">
        <Icon aria-hidden className="w-6 h-6 fill-current md:w-8 md:h-8" />
      </div>
      <div>
        <h2 id="test" className="text-xl font-bold text-gray-800 md:text-2xl md:mt-5 xl:mt-0 ">
          {title}
        </h2>
        <p className="mt-1 text-lg text-gray-600 md:mt-2 md:text-xl">{children}</p>
      </div>
    </li>
  );
}

Item.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  icon: PropTypes.elementType.isRequired,
};

Item.defaultProps = {
  className: null,
};

function ArrowLink({ children, to }) {
  return (
    <Link
      className="inline-flex items-center space-x-1 text-indigo-600 transition-colors group hover:text-indigo-800"
      to={to}
    >
      <span>{children}</span>
      <ArrowRightIcon className="block transition-transform fill-current opacity-90 group-hover:translate-x-1 group-hover:transform" />
    </Link>
  );
}

export default function ServicesIntro() {
  return (
    <Container className="mt-12 md:mt-20 lg:mt-28">
      <Header
        h1="We’re here to do it all"
        h2="Our services"
        text="We are ready to work with you at every stage, from product strategy and design to
          implementation and maintenance."
      />

      <div className="mt-10 xl:grid-cols-2 xl:grid xl:gap-8 md:mt-14 xl:items-center">
        <ul className="space-y-8 lg:gap-x-16 lg:gap-y-14 md:gap-x-14 md:gap-y-12 md:space-y-0 md:grid md:grid-cols-2 xl:block xl:space-y-10 xl:pr-8">
          <Item title="Product strategy" icon={StrategyIcon}>
            We uncover insights by competitive analysis and research, defining business and audience
            goals, establishing a clear vision and identifying success metrics.
            <span className="block mt-3">
              <ArrowLink to="/services#strategy">Learn more</ArrowLink>
            </span>
          </Item>
          <Item title="Product design" icon={DesignIcon}>
            Our team builds engaging user experiences for mobile apps, web products, and landing
            pages helping you attract new users and make them come back again and again.
            <span className="block mt-3">
              <ArrowLink to="/services#design">Learn more</ArrowLink>
            </span>
          </Item>
          <Item title="Development" icon={CodeIcon}>
            We’ll translate designs into accessible and responsive frontend interfaces. Our
            extensive experience in modern web technologies, allows us to always pick the best tech
            for the job.
            <span className="block mt-3">
              <ArrowLink to="/services#development">Learn more</ArrowLink>
            </span>
          </Item>
          <Item className="xl:hidden" title="Maintenance & improvement" icon={MaintenanceIcon}>
            When the product is built, we support you with ongoing design and development
            improvements. That includes the development of additional features, bug fixes, and
            analysis of user behavior to optimize your product.
            <span className="block mt-3">
              <ArrowLink to="/services#improvement">Learn more</ArrowLink>
            </span>
          </Item>
        </ul>
        <StaticImage
          className="hidden shrink-0 xl:block"
          src="../images/photos/office-board.jpg"
          alt="The team is running a design sprint"
          placeholder="blurred"
          layout="fullWidth"
          quality="90"
        />
      </div>
    </Container>
  );
}
