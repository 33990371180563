import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Container from './container';
import Header from './header';

export default function Hero() {
  return (
    <Container>
      <Header
        isMain
        className="mt-12 md:mt-20 lg:mt-28"
        h1="We help companies build simple and beautiful digital products."
        text="Volti Studio is a small team of designers and developers offering UI/UX design, mobile, and
        web development consulting."
      />

      <StaticImage
        className="mt-8 md:mt-12"
        src="../images/photos/office-hero.jpg"
        alt="A designer is working at her desk"
        placeholder="blurred"
        layout="fullWidth"
        quality="90"
      />
    </Container>
  );
}
