import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import Hero from '../components/hero';
import Advantages from '../components/advantages';
import ServicesIntro from '../components/services-intro';
import Container from '../components/container';

export default function IndexPage() {
  return (
    <Layout>
      <Hero />
      <Advantages />

      <Container className="grid items-start grid-cols-1 gap-6 mt-12 md:mt-20 lg:gap-8 lg:mt-28 md:grid-cols-2">
        <StaticImage
          src="../images/photos/office-vertical-long.jpg"
          alt="The team is working"
          placeholder="blurred"
          layout="fullWidth"
          quality="90"
        />
        <StaticImage
          src="../images/photos/drawing.jpg"
          alt="Sketching dashboard design with a pen"
          placeholder="blurred"
          layout="fullWidth"
          quality="90"
        />
      </Container>

      <Container className="mt-6 md:mt-20">
        <h2 className="text-sm font-bold tracking-wide text-indigo-600 uppercase md:text-base">
          Our goal
        </h2>

        <p className="md:max-w-[33em]  text-gray-500 text-xl md:text-4xl mt-2  md:mt-4 md:leading-snug">
          We like to see our clients as partners who have our team dedicated to their business for
          extended periods. This ongoing collaboration allows us to really get to know our clients
          and understand their values and vision to provide solutions they and their users are 100%
          satisfied with.
        </p>
      </Container>

      <ServicesIntro />
    </Layout>
  );
}
